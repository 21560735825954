import * as SentrySvelte from '@sentry/svelte';
import { BrowserTracing } from '@sentry/tracing';
import type { HandleClientError } from '@sveltejs/kit';

import { dev, version } from '$app/environment';
import { getErrorMessage, instanceOfWebRPCError } from '$lib/utils/getErrorMessage';

SentrySvelte.init({
	enabled: !dev && import.meta.env.MODE != 'testing',
	release: version,
	dsn: 'https://fbb88a9e0c264927aa124b0ebab13d78@o457413.ingest.sentry.io/6398589',
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,

	// Based on  https://docs.sentry.io/platforms/javascript/configuration/filtering/
	ignoreErrors: [
		// Random plugins/extensions
		'top.GLOBALS',
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		'originalCreateNotification',
		'canvas.contentDocument',
		'MyApp_RemoveAllHighlights',
		'http://tt.epicplay.com',
		"Can't find variable: ZiteReader",
		'jigsaw is not defined',
		'ComboSearch is not defined',
		'http://loading.retry.widdit.com/',
		'atomicFindClose',
		// Facebook borked
		'fb_xd_fragment',
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
		// reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268
		'bmi_SafeAddOnload',
		'EBCallBackMessageReceived',
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		'conduitPage',
		// Frankerfacez error (a twitch plugin that injects itself into the twitch hubs)
		/Name Collision for Module/,
		// Fetch errors, these are mostly caused by users cancelling requests (clicking on the X button on the browser)
		// Unfortunately this means that we are ignoring fetch errors that are not related to the user cancelling the request
		'TypeError: Failed to fetch',
		'TypeError: NetworkError when attempting to fetch resource.',
		'TypeError: cancelled',
		// Internal errors, that we don't want to send to sentry
		'email is already taken',
	],
	denyUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/chrome-extension:\/\//i,
		// Firefox extensions
		/moz-extension:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		// We dont want to capture dev errors
		/hubs.dev.alidalabs.com/i,
	],
});

SentrySvelte.setTag('svelteKit', 'browser');

export const handleError = (({ error, event }) => {
	// If the route id is null, we have a 404 error, ignoring those
	if (event.route.id) {
		let e = error;
		if (instanceOfWebRPCError(e)) {
			e = new Error(e.msg, { ...e });
		}
		SentrySvelte.captureException(e, { contexts: { sveltekit: { event } } });
	}

	return {
		message: getErrorMessage(error),
	};
}) satisfies HandleClientError;
