import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,11,13,2];

export const dictionary = {
		"/h/[[page]]/[confirm=confirmMatcher]": [~39,[11],[12]],
		"/h/[signup=signupMatcher]/[[recruitmentId]]": [40,[11,13],[12]],
		"/orgadmin/hubs": [41,[14]],
		"/orgadmin/organizations": [42,[14]],
		"/[hubPrefix=hubPrefixMatcher]/compliance/ccpa": [16,[2]],
		"/[hubPrefix=hubPrefixMatcher]/content/(manageContent)/comments": [19,[2,3]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/list": [21,[2,4,5]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/list/drafts": [22,[2,4,5]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/list/scheduled": [23,[2,4,5]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/list/sent": [24,[2,4,5]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/overview": [25,[2,4]],
		"/[hubPrefix=hubPrefixMatcher]/content/newsletters/overview/[overviewType=newslettersOverviewMatcher]": [26,[2,4]],
		"/[hubPrefix=hubPrefixMatcher]/content/(manageContent)/submissions": [20,[2,3]],
		"/[hubPrefix=hubPrefixMatcher]/content/(manageContent)/[contentType=manageContentTypeMatcher]": [17,[2,3]],
		"/[hubPrefix=hubPrefixMatcher]/content/(manageContent)/[postId]": [18,[2,3]],
		"/[hubPrefix=hubPrefixMatcher]/group/[type=groupTypeMatcher]": [27,[2]],
		"/[hubPrefix=hubPrefixMatcher]/notifications": [28,[2,6]],
		"/[hubPrefix=hubPrefixMatcher]/posts/[postSlug=endsOnInt]": [29,[2]],
		"/[hubPrefix=hubPrefixMatcher]/profile": [30,[2]],
		"/[hubPrefix=hubPrefixMatcher]/search": [31,[2]],
		"/[hubPrefix=hubPrefixMatcher]/settings/design/cover": [32,[2,7]],
		"/[hubPrefix=hubPrefixMatcher]/settings/design/header": [33,[2,7]],
		"/[hubPrefix=hubPrefixMatcher]/settings/design/text": [34,[2,7]],
		"/[hubPrefix=hubPrefixMatcher]/settings/integrations": [35,[2,8]],
		"/[hubPrefix=hubPrefixMatcher]/settings/member-data": [36,[2,9]],
		"/[hubPrefix=hubPrefixMatcher]/settings/user/profile-data": [38,[2,10]],
		"/[hubPrefix=hubPrefixMatcher]/settings/user/profile": [37,[2,10]],
		"/[hubPrefix=hubPrefixMatcher]/[...collection]": [15,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';